<template>
    <form ref="myform" method="POST" action="https://app-api.casalapin.com/checkout_qr">

    </form>
</template>

<script>
    export default {
        name: "FormPayKbank",
        mounted() {
            let script = document.createElement('script')
            script.setAttribute("src", "https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js");
            script.setAttribute('data-apikey', 'pkey_test_20647V7XqSJVoSxJK4Hu5TxAb68Maj5o6s4F6');
            script.setAttribute('data-payment-methods', this.$route.query.method);
            script.setAttribute('data-amount', this.$route.query.amount);
            script.setAttribute('data-order-id', this.$route.query.id);
            this.$refs.myform.appendChild(script);
        }
    }
</script>

<style>

</style>